import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAppState,
  IContent,
} from '../types';

const initialState: IAppState = {
  isTranslated: false,
  isError: false,
  isModalOpen: false,
  modalContent: null,
};

/* eslint-disable no-param-reassign */
const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLang: (state, action: PayloadAction<boolean>) => {
      state.isTranslated = action.payload;
    },
    setModalIsOpen: (state, action: PayloadAction<{
      isModalOpen: boolean,
      modalContent: IContent | null
    }>) => {
      state.isModalOpen = action.payload.isModalOpen;
      state.modalContent = action.payload.modalContent;
    },
    setIsError: (state, action: PayloadAction<boolean>) => {
      state.isError = action.payload;
    },
  },
});

export const { setLang, setIsError, setModalIsOpen } = appSlice.actions;
export default appSlice;
