export const firebaseProjectToken = 'a9d18d22-8514-4fbd-9e0a-24d46630c497';

export const firebaseVapidKey = 'BJ1rmMPdXaylYy7xCssRMAKV5BvYPCpj1o0ZoCRFShf1gmzk6tQQ83x_Zgn7c5CoW7Noqnqy-1ZxXrTNwYyCCaE';

export const firebaseConfig = {
  apiKey: 'AIzaSyDo3FUYNeF7Qvz6jtuKeCzx4Kr7FbBoLAI',
  authDomain: 'jfl-rb-a1.firebaseapp.com',
  projectId: 'jfl-rb-a1',
  storageBucket: 'jfl-rb-a1.appspot.com',
  messagingSenderId: '728912915199',
  appId: '1:728912915199:web:095305412403a31da6faca',
};
