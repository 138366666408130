export const BASE_PATH = '/video';
export const API_PATH = '/api/compact-auto';
export const GATE = '/gate';
export const SERVICE_ALIAS = 'rb-velcom-video';
export const CONDITIONS_MAIN = `/text/${SERVICE_ALIAS}/oferta/1`;
export const CONDITIONS_FOOTER = `/text/${SERVICE_ALIAS}/footer-no-subscription/1`;
export const ADDRESS_FOOTER = `/text/${SERVICE_ALIAS}/footer-under/1`;
export const HEADER_SUPPORT = `/text/${SERVICE_ALIAS}/header-support/1`;
export const GET_STATUS = '/api/status';
export const FIREBASE_TOKEN = `${BASE_PATH}/firebase/token`;
